import { ContentWrapper, Logo, spacing, Text } from '@elseu/sdu-titan';
import { GenialStartBanner } from '@elseu/sdu-titan-genial';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useAuthentication } from '@repo/auth';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useSiteConfig } from '../../context';
import { Favorites } from '../Favorites';
import { Footer } from '../Footer/Footer';
import { logos } from '../Logos';
import { MarketingBlock } from '../MarketingBlock/MarketingBlock';
import News from '../News/News';
import SearchBarWithInPageSearch from '../SearchBar/SearchBarWithInPageSearch';

const SearchContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${spacing(30)} auto ${spacing(8)};
  text-align: center;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: ${spacing(8)};
`;

const SearchHomePage = () => {
  useLingui();
  const { brandName, featureFlags, logo, siteUrl, slogan, version, urls } = useSiteConfig();
  const { isLoggedIn } = useAuthentication();
  const [isShown, setShown] = useState(false);

  const brand = useMemo(
    () =>
      process.env.NODE_ENV !== 'production' || siteUrl?.includes('test') || siteUrl?.includes('ota')
        ? `${brandName} ${version}`
        : brandName,
    [brandName, siteUrl, version],
  );

  const favorites = featureFlags.WITH_FAVORITES && isLoggedIn && <Favorites />;

  return (
    <ContentWrapper maxWidth={640} spaceAfter={8}>
      {!!featureFlags.WITH_BLUETICK_AI_CHAT && (
        <GenialStartBanner
          content={t`Beantwoord jouw onderzoeksvragen met behulp van AI`}
          href={urls.genialUrl}
        />
      )}
      <MarketingBlock />
      <SearchContainer>
        <StyledLogo
          brandName={logo ? undefined : brand}
          size={48}
          svg={logo ? logos[logo] : undefined}
        />

        <SearchBarWithInPageSearch isShownOnMobile isShown={isShown} onToggle={setShown} />
        {slogan && (
          <Text color="grey70" type="paragraphSmall">
            {slogan}
          </Text>
        )}
        {favorites}
      </SearchContainer>
      <News />
      <Footer />
    </ContentWrapper>
  );
};

export default SearchHomePage;
