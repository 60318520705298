import { Flex, Loader } from '@elseu/sdu-titan';
import {
  FavoritesHeader,
  FavoritesList,
  FavoritesListItem,
  useFavoritesList,
  useRemoveFavorite,
} from '@elseu/sdu-titan-search';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { FavoritesEmptyState } from './FavoritesEmptyState';
import { useGenerateRoute } from './hooks/useGenerateRoute';

const FavoritesContainer = styled.div.attrs({
  'data-testid': 'favorites-container',
})`
  width: 100%;
`;

const StyledLoaderContainer = styled(Flex)`
  height: 100px;
`;

const FavoritesContent = () => {
  const { sources, isLoading } = useFavoritesList();
  const removeFavorite = useRemoveFavorite();
  const generateRoute = useGenerateRoute();

  if (isLoading) {
    return (
      <StyledLoaderContainer alignItems="center" justifyContent="center">
        <Loader height={48} variant="spinner" />
      </StyledLoaderContainer>
    );
  }

  if (!sources.length) {
    return <FavoritesEmptyState />;
  }

  return (
    <FavoritesList>
      {sources.map(({ favoriteSource, userSource }) => {
        const link = generateRoute({ favoriteSource, userSource });

        return (
          <FavoritesListItem
            key={favoriteSource.id}
            link={link}
            onDelete={() => removeFavorite(favoriteSource)}
          >
            {userSource.title?.value || userSource.title?.key || t`Onbekend`}
          </FavoritesListItem>
        );
      })}
    </FavoritesList>
  );
};

const Favorites = () => {
  return (
    <FavoritesContainer>
      <FavoritesHeader />
      <FavoritesContent />
    </FavoritesContainer>
  );
};

export { Favorites };
