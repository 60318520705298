import type { useFavoritesList } from '@elseu/sdu-titan-search';
import { useSearchRootContext } from '@elseu/sdu-titan-search';

import { useSiteConfig } from '../../../context';

type UserSource = ReturnType<typeof useFavoritesList>['sources'][0]['userSource'];
type FavoritesSource = ReturnType<typeof useFavoritesList>['sources'][0]['favoriteSource'];

type GenerateUrlParams = {
  favoriteSource: Pick<FavoritesSource, 'name'>;
  userSource: Pick<UserSource, 'hasEditions' | 'title'>;
};

/**
 * A hook that returns a function to generate a route string based on the User, and Favorite source.
 */
export const useGenerateRoute = () => {
  const { urls } = useSiteConfig();
  const { searchUrlResolver } = useSearchRootContext();

  /**
   * Generate a route string based on the User, and Favorite source.
   *
   * @param userSource
   * @param favoriteSource
   */
  return ({ userSource, favoriteSource }: GenerateUrlParams): string => {
    if (userSource.hasEditions && userSource.title) {
      return urls.magazineUrl.replace(':magazineId', userSource.title.key);
    }

    if (typeof searchUrlResolver === 'function') {
      return searchUrlResolver({
        ro_source: favoriteSource.name,
        mode: 'browse',
      });
    }

    return '';
  };
};
