import { InPageSearch } from '@elseu/sdu-titan';

import type { SearchBarProps } from './SearchBar';
import { SearchBar } from './SearchBar';

const SearchBarWithInPageSearch = (props: SearchBarProps) => {
  return (
    <>
      <SearchBar {...props} />
      <InPageSearch />
    </>
  );
};

export default SearchBarWithInPageSearch;
