import type { AutocompleteSelection } from '@elseu/sdu-titan';
import { useGreaterThan } from '@elseu/sdu-titan';
import type { MenuItemProps } from '@elseu/sdu-titan/dist/types/components/Menu/MenuItem';
import type { AutocompleteSuggestions } from '@elseu/sdu-titan-search';
import { SearchBarAutocomplete } from '@elseu/sdu-titan-search';
import { t, Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import type * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSiteConfig } from '../../context/SiteConfigProvider';

export interface SearchBarProps {
  isShown: boolean;
  isShownOnMobile?: boolean;
  onToggle: (isShown: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ isShown, onToggle, isShownOnMobile = false }) => {
  const { brandName, facetsPreset, featureFlags, urls, searchTabs } = useSiteConfig();
  const { query: routerQuery, push, asPath, pathname } = useRouter();

  const isLarge = useGreaterThan('s');
  const urlQuery = (routerQuery.query as string) || '';

  const [searchQuery, setSearchQuery] = useState<string>(urlQuery);

  // a list of query-params which will be allowed to "stay" when starting a new search. All others will be stripped out of the routerQuery
  const queryParamsWhitelist = useMemo(
    () => [
      'query',
      'sort',
      facetsPreset.tabsFacet,
      // all facet names
      ...facetsPreset.facets.map(({ name }) => name),
    ],
    [facetsPreset],
  );

  useEffect(() => {
    setSearchQuery(urlQuery);
  }, [urlQuery]);

  const isInPageSearchEnabled = useMemo(() => {
    const shouldShowOnContentPage = Boolean(
      routerQuery.swsId &&
        (!routerQuery.tab || routerQuery.tab === 'commentaar' || routerQuery.tab === 'pn'),
    );
    return featureFlags.WITH_IN_PAGE_SEARCH && shouldShowOnContentPage;
  }, [featureFlags.WITH_IN_PAGE_SEARCH, routerQuery]);

  const isSearchWithinSourceEnabled = useMemo(() => {
    const isBrowseMode = routerQuery.mode === 'browse';
    /** Should also be made possible on the magazine page in the future */
    // const isMagazinePage = asPath.includes(urls.magazinesUrl);

    return featureFlags.WITH_SEARCH_WITHIN_SOURCE && isBrowseMode;
  }, [featureFlags.WITH_SEARCH_WITHIN_SOURCE, routerQuery.mode]);

  const hasContentTypeAutocomplete = useMemo(() => {
    /** Only enable the content type autocomplete on the homepage */
    return featureFlags.WITH_CONTENT_TYPE_AUTOCOMPLETE && pathname === '/';
  }, [featureFlags.WITH_CONTENT_TYPE_AUTOCOMPLETE, pathname]);

  const transformSuggestions = useCallback(
    (query: string, result: AutocompleteSuggestions) => [
      {
        name: <Trans>Resultaten binnen {brandName}</Trans>,
        results: query.length > 2 ? result.data.autocomplete.results : [],
      },
    ],
    [brandName],
  );

  const handleSubmit = useCallback(
    (query: string) => {
      const filteredRouterQuery: ParsedUrlQuery = {};

      queryParamsWhitelist.forEach((paramKey) => {
        if (routerQuery[paramKey]) {
          filteredRouterQuery[paramKey] = routerQuery[paramKey];
        }
      });

      push({
        // When at interne documenten tab, search inside interne documenten
        pathname: asPath.includes('/zoeken/interne-documenten')
          ? '/zoeken/interne-documenten'
          : urls.searchUrl,
        query: { ...filteredRouterQuery, query },
      });
      onToggle(false);
    },
    [asPath, push, onToggle, routerQuery, urls, queryParamsWhitelist],
  );

  const handleSelect = useCallback(
    ({ item }: AutocompleteSelection) => {
      handleSubmit(item?.text || '');
    },
    [handleSubmit],
  );

  const handleSourceSearchSelect = useCallback(
    (searchTerm: string) => {
      push({
        pathname: urls.searchUrl,
        query: { ...routerQuery, query: searchTerm, sort: 'relevance.descending' },
      });
      onToggle(false);
    },
    [onToggle, push, routerQuery, urls.searchUrl],
  );

  const handleContentTypeSelect = useCallback(
    ({ query, contentTypes }: { query: string; contentTypes: string[] }) => {
      push({
        pathname: urls.searchUrl,
        query: { ...routerQuery, query, [facetsPreset.tabsFacet]: contentTypes.join(',') },
      });
      onToggle(false);
    },
    [facetsPreset.tabsFacet, onToggle, push, routerQuery, urls.searchUrl],
  );

  return (
    <SearchBarAutocomplete
      hasAllResultsLink
      inputProps={{
        placeholder: t`Zoeken naar kernwoorden, artikelen, of...`,
        label: t`Zoekterm`,
        name: 'query',
      }}
      isHidden={!isShown && !isLarge && !isShownOnMobile}
      isInPageSearchEnabled={isInPageSearchEnabled}
      isSearchWithinSourceEnabled={isSearchWithinSourceEnabled}
      labelAllResults={<Trans>Toon alle zoekresultaten</Trans>}
      labelCancelButton={<Trans>Annuleren</Trans>}
      labelNoResults={t`Geen zoeksuggesties`}
      mapSuggestionToMenuItemProps={({ text }) =>
        ({
          item: { label: text },
        }) as Partial<MenuItemProps>
      }
      query={searchQuery}
      searchTabs={searchTabs}
      shouldCtrlF={false}
      shouldRenderMobileView={!isLarge && !isShownOnMobile}
      shouldShowContentTypeSelector={hasContentTypeAutocomplete}
      transformSuggestions={transformSuggestions}
      onCancel={() => onToggle(false)}
      onContentTypeSelect={handleContentTypeSelect}
      onQueryChange={setSearchQuery}
      onSelect={handleSelect}
      onSourceSearchSelect={handleSourceSearchSelect}
      onSubmit={handleSubmit}
    />
  );
};

export { SearchBar };
