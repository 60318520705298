import { Box, Heading, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';

const FavoritesEmptyState = () => {
  return (
    <Box backgroundColor="grey5" borderRadius={1} px={6} py={6} spaceAfter={6}>
      <Heading heading="h4" spaceAfter={2}>
        <Trans>Je hebt momenteel geen favorieten</Trans>
      </Heading>
      <Text color="grey70" type="label">
        <Trans>Voeg een favoriet toe door op `Toevoegen` te klikken</Trans>
      </Text>
    </Box>
  );
};

export { FavoritesEmptyState };
